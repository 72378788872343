<template>
  <div id="Window" class="layout" @click="clickedItem = null" @drop.stop.prevent="uploadFile($event, thisFolder)"
       @dragover.stop.prevent>
    <!--    <div v-if="listStyle" class="list">-->
    <!--      <ul>-->
    <!--        <li v-for="item in info.folder">-->
    <!--          <div class="icon">-->
    <!--            <f-icon icon="folder" :style="{color: getValue('colors', item.folder_color)}" class="folder"/>-->
    <!--            <f-icon :icon="getValue('icons', item.folder_icon)" v-if="item.folder_icon != '1'" class="icon"/>-->
    <!--          </div>-->
    <!--          {{item.folder_name}}-->
    <!--        </li>-->
    <!--      </ul>-->
    <!--    </div>-->
    <div v-if="!listStyle" class="grid" @click.right.stop.prevent="openContext($event, 'bg', thisFolder)">
      <ul>
        <li
            class="folderItem"
            v-for="item in folderList"
            :class="clickedItem == item ? 'clicked':''"
            @click.stop.prevent="clickedItem = item"
            @click.right.stop.prevent="openContext($event, 'folder', item)"
            draggable="true"
            @drop.stop.prevent="uploadFile($event, item)"
            @dragstart="dragStart($event, item)"
            @dragend="dragEnd($event)"
        >
          <div class="icon" @dblclick="openFolder(item)">
            <folder-icon :icon="item.folder_icon" :color="item.folder_color" :key="item.folder_id"/>
            <i class="shared" v-if="item.shared">
              <f-icon icon="share-alt"/>
              <h5>공유중인 폴더입니다.</h5>
            </i>
          </div>
          <span>{{ item.folder_name }}</span>
        </li>
        <li
            class="fileItem"
            v-for="item in fileList"
            :class="clickedItem == item ? 'clicked':''"
            @click.stop.prevent="clickedItem = item"
            @click.right.stop.prevent="openContext($event, 'file', item)"
            draggable="true"
            @dragstart="dragStart($event, item)"
            @dragend="dragEnd($event)"
        >
          <div class="icon">
            <file-icon :file_type="item.file_type" :key="item.file_id"/>
          </div>
          <span>{{ item.ori_name }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/utils/EventBus.js";
import {mapGetters} from "vuex"
import APIUtils from "@/utils/Network/APIUtils";
import $ from 'jquery'

export default {
  computed: {
    ...mapGetters({info: "getSaveInfo"}),
  },
  data() {
    return {
      listStyle: false,
      clickedItem: null,
      folderList: null,
      fileList: null,
      thisFolder: {folder_id: 'root', folder_name: "/"},
    }
  },
  created() {
    let objThis = this
    EventBus.$on("bus:openFolder", function (item) {
      objThis.setWindow(item)
    })
    EventBus.$on("bus:refreshWindow", function(folder_id){
      if(objThis.thisFolder.folder_id == folder_id)
        objThis.setWindow(objThis.thisFolder.folder_id)
    })
    this.setWindow()
  },
  methods: {
    getValue(type, code) {
      return this[type].filter(el => el.code == code)[0].value
    },
    openContext(e, type, item) {
      this.clickedItem = item
      EventBus.$emit("bus:openContext", {x: e.clientX, y: e.clientY, type: type, target: e.target, item: item})
    },
    openFolder(item) {
      EventBus.$emit("bus:openFolder", item.folder_id)
    },
    setWindow(folder_id) {
      folder_id = folder_id || 'root'
      APIUtils.window_list({folder_id}).then(res=>{
        this.thisFolder = res.body.folderInfo || {folder_id: 'root', folder_name: "/"}
        this.folderList = res.body.folderList
        this.fileList = res.body.fileList || []
      })
    },
    uploadFile(e, t) {
      if (e.dataTransfer.files.length) {
        if (t.folder_id == null) {
          alert("최상위 폴더에는 파일을 저장할 수 없습니다.")
        } else {
          let uploadData = {
            file: e.dataTransfer.files[0],
            parent: t.folder_id,
            overwrite: false
          }
          APIUtils.file_upload(uploadData).then(res => {
            this.fileList.push(res.body)
          }).catch(err => {
            console.log(err)
            if (err.resultCode == 30002) {
              if (confirm(err.desc)) {
                uploadData.overwrite = true
                APIUtils.file_upload(uploadData).then(res2 => {
                  console.log(res2)
                }).catch(err => {
                  console.log(err)
                })
              }
            }
          })
        }
      } else {
        let target_id = e.dataTransfer.getData('text')
        let parent = t.folder_id
        let target_type = target_id[0]
        if(this.thisFolder.folder_id != parent && target_id!=parent) {
          console.log(target_id, parent, target_type)
          switch (target_type) {
            case "O":
              APIUtils.file_update({file_id: target_id, parent: parent}).then(res => {
                if (res.resultCode == 0) {
                  EventBus.$emit("bus:refreshWindow", this.thisFolder.folder_id)
                }
              })
              break;
            case "F":
              APIUtils.folder_move({folder_id: target_id, parent: parent}).then(res => {
                if (res.resultCode == 0) {
                  EventBus.$emit("bus:refreshWindow", this.thisFolder.folder_id)
                }
              })
              break;
          }
        }
      }
    },
    dragStart(e, t) {
      e.target.style.opacity = 0.5;
      if (t.folder_id) {
        e.dataTransfer.setData('text', t.folder_id);
      }
      if (t.file_id) {
        e.dataTransfer.setData('text', t.file_id);
        e.dataTransfer.setData("DownloadURL", `application/octet-stream:${t.ori_name}:${APIUtils.DOWN_URL(t.file_id)}`);
      }
    },
    dragEnd(e) {
      e.target.style.opacity = 1.0;
    },
    // dragOver(e, swc) {
    //   let tar = e.path.filter(el => el.className == "folderItem")
    //   if (tar.length) {
    //     if (swc) {
    //       $(tar[0]).css({border: "1px dotted red"})
    //     } else {
    //       $(tar[0]).css({border: "1px solid #dbdbdb"})
    //     }
    //   }
    // }
  }
};
</script>

<style scoped></style>
